section {
  padding: 10px 0;
  overflow: hidden;
  width: 100%;
}

.studentVideo{
  height: 180px;
}

.myVideoDiv{
  position: fixed;
  display: grid;
  top: 1rem;
  right: 1rem;
  padding: 10px;
}

.myVideo{
  height: 150px;

}

.myVideoControls{
border-radius: 50%;
background: #FCD21C;
color: #fff;
padding: 5px 10px 5px 10px;
border: 0px;
transition: 0.3s;
margin: 2px;
}

.myVideoControls:hover{
background: #fff;
color: #FCD21C;
}

.myVideoControls-danger{
border-radius: 50%;
background: #dc3545;
color: #fff;
padding: 5px 10px 5px 10px;
border: 0px;
transition: 0.3s;
margin: 2px;
}

.myVideoControls-danger:hover{
background: #fff;
color: #FCD21C;
}

.myChatDiv{
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 12.5rem;
}

.myVideoCallDiv{
  background: #fff;
  position: fixed;
  display: grid;
  bottom: 0;
  left: 0;
  padding: 10px;
  margin: 5px;
}

.recording-div{
  background-color: #fff;
  position: fixed;
  left: 0;
  bottom: 1vh;
  z-index:100;
  text-align: center;
  width: 15%;
}

.students-row{
  display: inherit;
  overflow-x: scroll;
}

.custom-block-indent-a {
  margin-left: 10%;
}

.custom-block-indent-b {
  margin-left: 20%;
}

.custom-block-indent-c {
  margin-left: 30%;
}