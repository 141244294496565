.NarbarItems {
	background: linear-gradient(90deg, rgb(	252, 210, 28) 0%, rgba(	252, 210, 28) 100%);
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;

}

.navbar-logo {
	color: #fff;
	justify-self: start;
	margin-left: 20px;
	cursor: pointer;
	max-width: 200px;
	padding: 2.5%;
}

.fa-react {
	margin-left: 0.5 rem;
	font-size: 1.6 rem;
}

.nav-menu {
	display: grid;
	grid-template-columns: repeat(7, auto);
	grid-gap: 8px;
	list-style: none;
	text-align: center;
	width: 60vw;
	justify-content: end;
	margin-right: 2rem;
	margin-bottom: 0;
	margin-top: 0;
}

.nav-menu li:hover{
	background-color: white;
	border-radius: 16px;
	transition: all 1s ease-out;
}

.nav-links {
	color: #fff;
	text-decoration: none;
	font-weight: 600;
	padding: 7.5px 10px;
}

.nav-links:hover{
	background-color: white;
	color: #FCD21C;
	border-radius: 16px;
	transition: all 0.5s ease-out;
}


.dropdown-links {
	color: #343a40;
	text-decoration: none;
	font-weight: 600;
	padding: 7.5px 10px;
}

.dropdown-links-links:hover{
	background-color: #FCD21C;
	color: #343a40;
	border-radius: 16px;
	transition: all 0.5s ease-out;
}

.active{
	background-color: #fff;
	border-radius: 16px;
	color: #FCD21C;
}


.fa-bars {
	color: black;
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}

@media screen and (max-width:  960px){
	.NarbarItems{
		position: relative;
	}

	.nav-menu{
		display: flex;
		flex-direction: column;
		width:  100%;
		position: absolute;
		top: 80px;
		left: -800px;
		opacity: 1;
		transition: all 0.5s ease;
	}

	.nav-menu.active{
		background: #FCD21C;
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
	}

	.nav-links{
		text-align: center;
		padding: 1rem;
		width: 100%;
		display: table;
	}

	.nav-links:hover{
		background-color: white;
		border-radius: 0;
	}

	.navbar-logo{
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(25%, 0%);

	}

	.menu-icon{
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
	}
}



















