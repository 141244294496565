/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 10px 0;
  overflow: hidden;
  width: 80%;
}

hr {
  height:2px !important;
  border-width:0;
  background-color:#000;
  color: #000;
  opacity: 1 !important;
}

.section-title {
  padding-bottom: 10px;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.section-title h2 {
  font-size: 18px;
  font-weight: 700;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #FCD21C;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #FCD21C;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #2a2c39;
}
/*--------------------------------------------------------------
# Teacher Signup
--------------------------------------------------------------*/

/*** toggle button group ***/

.topic-button-container {
    display: inline-block;
    margin-right: 10px;
}

.topic-button {
    margin-right: 15px;
    margin-bottom: 35px;
    background-color: #FCD21C;
    font-size: 16px;
    font-weight: 700;
    color: #FFF;
    border-width: 1px;
    border-color: lightgray;
    transition: background-color 0.3s ease;
}

.topic-button.on {
    background-color: #FCD21C;
    color: black;
    border-width:thick;
}

.topic-button.off {
    background-color: #FCD21C;
    color: lightgrey;
    border-width: thick;
}
.topic-button:focus{

}
.topic-button:active{

}
.topic-button:hover {
    background-color: #FCD21C;
    color: black;
    cursor: pointer;
}

/*Agenda*/
.text-center {
    margin-bottom: 0; /* Adjust the bottom margin */
}

.div-scroll-y {
    margin-top: 0; /* Adjust the top margin */
}
.row {
    margin-bottom: 0; /* Adjust the bottom margin */
}

.col-lg-6 {
    margin-top: 0; /* Adjust the top margin */
}
h5.text-center {
    margin-bottom: 0; /* Adjust the bottom margin */
}

/*--------------------------------------------------------------
# Institution Signup
--------------------------------------------------------------*/

.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.pricing .course-box {
    height: 350px !important;
    padding: 20px !important;
    background: #fff !important;
    text-align: center !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12) !important;
    border-radius: 5px !important;
    position: relative !important;
    overflow: hidden !important;
    animation: fadeInAnimation ease 1s !important;
    animation-iteration-count: 1 !important;
    animation-fill-mode: forwards !important;
    box-sizing: border-box !important;
}

.pricing .course-box:hover {
    height: 350px !important;
    padding: 20px !important;
    margin: 0 !important;
    transform: none !important;
    transition: none !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12) !important;
    box-sizing: border-box !important;
}

.pricing .package-box {
    height: 640px !important;
    padding: 20px !important;
    background: #fff !important;
    text-align: center !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12) !important;
    border-radius: 5px !important;
    position: relative !important;
    overflow: hidden !important;
    animation: fadeInAnimation ease 1s !important;
    animation-iteration-count: 1 !important;
    animation-fill-mode: forwards !important;
    box-sizing: border-box !important;
}

.pricing .package-box:hover {
    height: 640px !important;
    padding: 20px !important;
    margin: 0 !important;
    transform: none !important;
    transition: none !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12) !important;
    box-sizing: border-box !important;
}

/* Buy Courses (Institution Signup) */

.pricing .teachers-box {
    max-height: 360px !important;
    width: 1000px !important;
    padding: 10px !important;
    background: #fff !important;
    text-align: center !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12) !important;
    border-radius: 5px !important;
    position: relative !important;
    overflow: hidden !important;
    animation: fadeInAnimation ease 1s !important;
    animation-iteration-count: 1 !important;
    animation-fill-mode: forwards !important;
    box-sizing: border-box !important;

}

.teachers-box:hover {
    margin: 0 !important;
    transform: none !important;
    transition: none !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12) !important;
    box-sizing: border-box !important;
}

.teachers-box h3 {
    font-size: 17px !important;
    margin-bottom: 5px !important;
    margin-top:-12px !important;
    padding: 20px !important;
}

.teachers-box .btn-wrap-next {
    margin: -15px -15px -15px -15px !important;
    padding: 16px 8px !important;
    background: #f8f8f8;
    text-align: right;
}

.pricing h3 {
    font-weight: 400;
    margin: -20px -20px 25px -20px;
    padding: 30px 15px;
    font-size: 18px;
    font-weight: 600;
    color: #777777;
    background: #f8f8f8;
}

.pricing h4 {
  font-size: 36px;
  color: #FCD21C;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing ul li {
  padding-bottom: 16px;
}

.pricing ul i {
  color: #FCD21C;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
    position:absolute;
    width:100%;
    margin: 20px -20px -20px -20px;
    bottom: 20px;
    padding: 20px 15px;
    background: #f8f8f8;
    text-align: center;
}
.pricing .btn-wrap-next {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: right;
}

.pricing .btn-buy {
    background: #FCD21C;
    display: inline-block;
    padding: 8px 35px 10px 35px;
    border: 1px solid lightgray;
    border-radius: 50px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    transition: 0.3s;
    margin: 0px 3px 0px 3px;
    text-align: center;
    width: auto;
    height: 41px;
}

.pricing .btn-buy:hover {
    background: #fff;
    color: #777777;
    cursor:pointer;
    /*border: solid 1px #FCD21C;*/
}

.pricing .btn-course {
    background: #FCD21C;
    display: inline-block;
    padding: 6px 10px 6px 10px;
    border: 1px solid lightgray;
    border-radius: 6px;
    color: #fff;
    transition: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    transition: 0.3s;
    margin: 4px 4px 4px 4px;
}

.pricing .btn-course:hover {
    background: #005FAB;
    color: #FFF;
    cursor: pointer;
    /*border: solid 1px #FCD21C;*/
}

.pricing .btn-course:active {
    background: #005FAB;
    color: #FFF;
    cursor: pointer;
    /*border: solid 1px #FCD21C;*/
}

.pricing .featured {
  border: 2px solid #FCD21C;
}

.pricing .featured h3 {
  color: #fff;
  background: #FCD21C;
}

.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #01e1ff;
  color: #fff;
}
.scroll{
  overflow-x: auto;
  flex-wrap: inherit;
  margin-top: 10px;
}

.box:hover h3,
.course-box:hover h3,
.package-box:hover h3,
.teachers-box:hover h3 {
    color: #fff;
    background: #FCD21C;
    transition: 0.5s;
}

/* Teacher List (institution Singup) */

#title {
    text-align: center;
    font-family: arial, sans-serif;
}
.selected {
    background-color: #005FAB;
    color:#fff;
    opacity: 1;
}


#teachers {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    border-collapse: collapse;
    border: 1px solid #ddd;
    width: 100%;
    border-collapse: collapse;
    color:black;
}

#teachers thead {
    position: sticky;
    top: 0;
    background-color: blue;
    color:black !important;
    z-index: 1;
}

#teachers td, #teachers th {
    border: 1px solid #ddd;
    padding: 9px;
}

#teachers tr:hover {
    background: rgba(0, 95, 171, .1) !important;
    cursor: pointer;
}

#teachers tr:active {
    background-color: #005FAB !important;
    color: #FFF;
    cursor: pointer;
}

#teachers tr.selected {
    background-color: #005FAB !important;
    color: #FFF;
    opacity:1;
}

#teachers th {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;
    background-color: #FCD21C;
    color: white;
    font-size: 15px;
}
 .scrollable-table {
    max-height: 280px; /* Set the desired max height for the table container */
    overflow-y: auto; /* Enable vertical scrolling */
}



/* Progress Bar */

.previous {
  color: #2a2c39;
  display:block;
}
.round {
  border-radius: 50%;
}
.progressbar-section{
  text-align: center;
  margin-top: 10px;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

/* Progress Bar */

#progressbar {
    /*overflow: hidden;*/
    /*color: lightgrey*/
    padding-left: 0px !important;
}

#progressbar .progressactive {
    color: #FCD21C;
}

#progressbar li {
    list-style-type: none;
    font-size: 15px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400
}

#progressbar #packages:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f51e"
}

#progressbar #courses:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f0d6"
}

#progressbar #information:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f19c"
}

#progressbar #confirm:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f058"
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1
}

#progressbar li.progressactive:before,
#progressbar li.progressactive:after {
    background: #FCD21C
}
.table-cell{
  text-align: left;
}
#informationsection{
  display: none;
}
.information-card{
  text-align: left;
}
.red{
  color: red;
}
.background{
  background: #fff;
  opacity: 0.9;
  border-radius: 50px;
  align-content:center;
}
#overlay {
  position: fixed;
}
.center{
  text-align: center;
}
#finish{
  display: none;
}

@keyframes scaling {
  From {
      -webkit-transform: scale(1);
  }
  To {
      -webkit-transform: scale(1.2);
  }
}
#tick {
  width: 20%;
-webkit-animation: scaling 1s infinite alternate;
}
.thankyou-message{
  font-weight: 500;
}
.button-Next{
  border: 0px;
  margin-left: 1% !important;
  background: transparent;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
.no-padding{
  padding: 0px !important;
}

.no-margin{
  margin: 0px !important;
}
.align-left{
  text-align: left;
}
.align-right{
  text-align: right;
}
.icon-cog {
  color: #FCD21C;
}
.transparent-bg{
  background: transparent !important;
  color: #fff;
}